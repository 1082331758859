<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 margin-top-book2">
          <c-card title="사업개요" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline>
                <c-btn
                  v-if="editable && !nodataFlag"
                  label="공사안전보건대장 출력"
                  icon="print"
                  color="amber-7"
                  @btnClicked="printProjectBook"
                />
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="일괄 저장"
                  icon="save"
                  @beforeAction="saveInfo"
                  @btnCallback="saveCallback"
                />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-6 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="공사명"
                  name="plantName"
                  v-model="data.info.plantName"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="현장주소"
                  name="projectAddress"
                  v-model="data.info.projectAddress"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                <c-datepicker
                  :required="true"
                  :editable="editable"
                  :range="true"
                  label="공사 기간"
                  name="projectTerm"
                  v-model="data.info.projectTerm"
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  type="number"
                  :required="true"
                  :editable="editable"
                  label="공사금액"
                  name="projectCost"
                  suffix="원"
                  v-model="data.info.projectCost"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-2 col-lg-2 col-xl-2">
                <c-datepicker
                  :editable="editable"
                  label="작성(변경)일"
                  name="writeDt"
                  v-model="data.info.writeDt"
                />
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 margin-top-book">
          <c-card title="발주자" class="cardClassDetailForm" topClass="topcolor-blue">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="editable"
                  label="회사명"
                  name="shOrderCompany"
                  v-model="data.info.shOrderCompany"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="editable"
                  label="전화번호"
                  name="shOrderPhone"
                  v-model="data.info.shOrderPhone"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="editable"
                  label="대표자"
                  name="shOrderRepresentative"
                  v-model="data.info.shOrderRepresentative"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="editable"
                  label="지정된 담당자"
                  name="shOrderManager"
                  v-model="data.info.shOrderManager"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="editable"
                  label="주소"
                  name="shOrderAddress"
                  v-model="data.info.shOrderAddress"
                >
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 margin-top-book">
          <c-card title="설계자" class="cardClassDetailForm" topClass="topcolor-blue">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="editable"
                  label="회사명"
                  name="shDesignCompany"
                  v-model="data.info.shDesignCompany"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="editable"
                  label="전화번호"
                  name="shDesignPhone"
                  v-model="data.info.shDesignPhone"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="editable"
                  label="대표자"
                  name="shDesignRepresentative"
                  v-model="data.info.shDesignRepresentative"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="editable"
                  label="설계안전보건대장 담당자"
                  name="shDesignManager"
                  v-model="data.info.shDesignManager"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="editable"
                  label="주소"
                  name="shDesignAddress"
                  v-model="data.info.shDesignAddress"
                >
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 margin-top-book">
          <c-card title="시공자" class="cardClassDetailForm" topClass="topcolor-blue">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="editable"
                  label="회사명"
                  name="shConstructorCompany"
                  v-model="data.info.shConstructorCompany"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="editable"
                  label="전화번호"
                  name="shConstructorPhone"
                  v-model="data.info.shConstructorPhone"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="editable"
                  label="대표자"
                  name="shConstructorRepresentative"
                  v-model="data.info.shConstructorRepresentative"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="editable"
                  label="현장소장"
                  name="shConstructorHead"
                  v-model="data.info.shConstructorHead"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="editable"
                  label="공사안전보건대장 작성 담당자(연락처)"
                  name="shConstructorManager"
                  v-model="data.info.shConstructorManager"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="editable"
                  label="주소"
                  name="shConstructorAddress"
                  v-model="data.info.shConstructorAddress"
                >
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 margin-top-book">
          <c-card title="건설사업관리기술(감리)" class="cardClassDetailForm" topClass="topcolor-blue">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="editable"
                  label="회사명"
                  name="shInspectionCompany"
                  v-model="data.info.shInspectionCompany"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="editable"
                  label="전화번호"
                  name="shInspectionPhone"
                  v-model="data.info.shInspectionPhone"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="editable"
                  label="대표자"
                  name="shInspectionRepresentative"
                  v-model="data.info.shInspectionRepresentative"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="editable"
                  label="현장 담당자"
                  name="shInspectionManager"
                  v-model="data.info.shInspectionManager"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="editable"
                  label="주소"
                  name="shInspectionAddress"
                  v-model="data.info.shInspectionAddress"
                >
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 margin-top-book">
          <c-table
            ref="grid1"
            topBorderClass="topcolor-blue"
            title="공사개요"
            tableId="grid1"
            :columns="grid1.columns"
            :editable="editable"
            :data="grid1.data"
            selection="multiple"
            rowKey="projectBookSummaryCd"
            :isFullScreen="false"
            :columnSetting="false"
            :filtering="false"
            :usePaging="false"
            :hideBottom="true"
            :isExcelDown="false"
            gridHeight="252px"
          >
            <template slot="table-button">
              <q-btn-group outline >
                <c-btn v-if="editable" label="추가" icon="add" @btnClicked="addrow1" />
                <c-btn v-if="editable" label="삭제" icon="remove" @btnClicked="deleterow1" />
              </q-btn-group>
            </template>
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 margin-top-book">
          <c-card title="그 외 정보" class="cardClassDetailForm" topClass="topcolor-blue">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-select
                  :editable="editable"
                  codeGroupCd="EXAMINE_RESULT"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="examineResult"
                  label="유해위험방지계획서 심사결과"
                  v-model="data.info.examineResult"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="산업재해예방 지도 계약여부"
                  name="guidanceFlag"
                  v-model="data.info.guidanceFlag"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-textarea
                  :counter="true"
                  :maxlength="500"
                  :editable="editable"
                  :rows="4"
                  label="특수구조물 개요"
                  v-model="data.info.specialStructureRemark"
                  name="specialStructureRemark">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-textarea
                  :counter="true"
                  :maxlength="500"
                  :editable="editable"
                  :rows="4"
                  label="주요공법"
                  v-model="data.info.majorMethod"
                  name="majorMethod">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 margin-top-book">
          <c-table
            ref="grid2"
            topBorderClass="topcolor-orange"
            title="설계안전보건대장의 안전보건조치 이행계획"
            tableId="grid2"
            :columns="grid2.columns"
            :editable="editable"
            :data="grid2.data"
            selection="multiple"
            rowKey="projectBookPlanCd"
            :isFullScreen="false"
            :columnSetting="false"
            :filtering="false"
            :usePaging="false"
            :hideBottom="true"
            gridHeight="252px"
            :isExcelDown="false"
          >
            <template slot="table-button">
              <q-btn-group outline >
                <c-btn v-if="editable" label="추가" icon="add" @btnClicked="addrow2" />
                <c-btn v-if="editable" label="삭제" icon="remove" @btnClicked="deleterow2" />
              </q-btn-group>
            </template>
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 margin-top-book">
          <c-table
            ref="grid3"
            topBorderClass="topcolor-orange"
            title="유해위험방지계획서 확인결과"
            tableId="grid3"
            :columns="grid3.columns"
            :editable="editable"
            :data="grid3.data"
            selection="multiple"
            rowKey="projectBookMeasureCd"
            :isFullScreen="false"
            :columnSetting="false"
            :filtering="false"
            :usePaging="false"
            :hideBottom="true"
            gridHeight="252px"
            :isExcelDown="false"
          >
            <template slot="table-button">
              <q-btn-group outline >
                <c-btn v-if="editable" label="추가" icon="add" @btnClicked="addrow3" />
                <c-btn v-if="editable" label="삭제" icon="remove" @btnClicked="deleterow3" />
              </q-btn-group>
            </template>
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 margin-top-book">
          <c-table
            ref="grid4"
            topBorderClass="topcolor-orange"
            title="산업안전보건관리비 변경내역"
            tableId="grid4"
            :editable="editable"
            :columns="grid4.columns"
            :data="grid4.data"
            selection="multiple"
            rowKey="projectBookSafetyCostCd"
            :isFullScreen="false"
            :columnSetting="false"
            :filtering="false"
            :usePaging="false"
            :hideBottom="true"
            gridHeight="252px"
            :isExcelDown="false"
          >
            <template slot="table-button">
              <q-btn-group outline >
                <c-btn v-if="editable" label="추가" icon="add" @btnClicked="addrow4" />
                <c-btn v-if="editable" label="삭제" icon="remove" @btnClicked="deleterow4" />
              </q-btn-group>
            </template>
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 margin-top-book">
          <c-table
            ref="grid5"
            topBorderClass="topcolor-orange"
            title="산업재해예방 지도결과 및 조치내용"
            tableId="grid5"
            :columns="grid5.columns"
            :editable="editable"
            :data="grid5.data"
            selection="multiple"
            rowKey="projectBookInstructCd"
            :isFullScreen="false"
            :columnSetting="false"
            :filtering="false"
            :usePaging="false"
            :hideBottom="true"
            gridHeight="252px"
            :isExcelDown="false"
          >
            <template slot="table-button">
              <q-btn-group outline >
                <c-btn v-if="editable" label="추가" icon="add" @btnClicked="addrow5" />
                <c-btn v-if="editable" label="삭제" icon="remove" @btnClicked="deleterow5" />
              </q-btn-group>
            </template>
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 margin-top-book">
          <c-card title="작성자" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  label="소속"
                  name="writeUserOrg"
                  v-model="data.info.writeUserOrg"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  label="직위"
                  name="writeUserPosition"
                  v-model="data.info.writeUserPosition"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  label="자격"
                  name="writeUserQualification"
                  v-model="data.info.writeUserQualification"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  label="성명"
                  name="writeUserName"
                  v-model="data.info.writeUserName"
                >
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 margin-top-book">
          <c-card title="확인자" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  label="소속"
                  name="checkUserOrg"
                  v-model="data.info.checkUserOrg"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  label="직위"
                  name="checkUserPosition"
                  v-model="data.info.checkUserPosition"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  label="자격"
                  name="checkUserQualification"
                  v-model="data.info.checkUserQualification"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  label="성명"
                  name="checkUserName"
                  v-model="data.info.checkUserName"
                >
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
      </div>
    </q-form>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'project-book-structure',
  props: {
    param: {
      type: Object,
      default: () => ({
        plantCd: '',
        updateFlag: '',
      }),
    },
  },
  data() {
    return {
      nodataFlag: false,
      mappingType: 'POST',
      isSave: false,
      infoUrl: '',
      saveUrl: transactionConfig.pjm.book.insert.url,
      deleteUrl1: '',
      deleteUrl2: '',
      deleteUrl3: '',
      deleteUrl4: '',
      deleteUrl5: '',
      printUrl: '',
      data: {
        info: {
          editFlag: '',
          plantCd: '',  // 공사현장코드
          plantName: '',  // 공사명
          projectAddress: '',  // 현장주소
          projectTerm: [],
          projectStartDt: '',  // 공사시작일자
          projectEndDt: '',  // 공사종료일자
          projectCost: '',  // 공사금액
          shOrderCompany: '',  // 발주자-회사명
          shOrderPhone: '',  // 발주자-전화번호
          shOrderRepresentative: '',  // 발주자-대표자
          shOrderManager: '',  // 발주자-지정된담당자
          shOrderAddress: '',  // 발주자-주소
          shDesignCompany: '',  // 설계자-회사명
          shDesignPhone: '',  // 설계자-전화번호
          shDesignRepresentative: '',  // 설계자-대표자
          shDesignManager: '',  // 설계자-설계안전보건담당자
          shDesignAddress: '',  // 설계자-주소
          shConstructorCompany: '',  // 시공자-회사명
          shConstructorPhone: '',  // 시공자-전화번호
          shConstructorRepresentative: '',  // 시공자-대표자
          shConstructorManager: '',  // 시공자-공사안전보건대장작성담당자
          shConstructorManagerPhone: '',  // 시공자-공사안전보건대장작성담당자 연락처
          shConstructorHead: '',  // 시공자-현장소장
          shConstructorAddress: '',  // 시공자-주소
          shInspectionCompany: '',  // 감리-회사명
          shInspectionPhone: '',  // 감리-전화번호
          shInspectionRepresentative: '',  // 감리-대표자
          shInspectionManager: '',  // 감리-지정된담당자
          shInspectionAddress: '',  // 감리-주소
          specialStructureRemark: '',  // 특수 구조물 개요
          majorMethod: '',  // 주요 공법
          examineResult: null,  // 심사결과
          guidanceFlag: 'N',  // 산업재해예방 지도 계약여부
          writeDt: '',  // 작성(변경)일자
          writeUserOrg: '',  // 작성자 소속
          writeUserPosition: '',  // 작성자 직위
          writeUserQualification: '',  // 작성자 자격
          writeUserName: '',  // 작성자 성명
          checkUserOrg: '',  // 확인자 소속
          checkUserPosition: '',  // 확인자 직위
          checkUserQualification: '',  // 확인자 자격
          checkUserName: '',  // 확인자 성명
        },
        summaryList: [],
        instructList: [],
        measureList: [],
        planList: [],
        safetycodeList: [],
      },
      editable: false,
      grid1: { // 공사개요
        columns: [
          {
            name: 'mainStructure',
            field: 'mainStructure',
            label: '주요구조물',
            align: 'center',
            style: 'width:16%',
            type: 'text',
            sortable: true,
          },
          {
            name: 'structure',
            field: 'structure',
            label: '구조',
            align: 'center',
            type: 'text',
            style: 'width:16%',
            sortable: true,
          },
          {
            name: 'gaeso',
            field: 'gaeso',
            label: '개소',
            align: 'center',
            type: 'text',
            style: 'width:16%',
            sortable: true,
          },
          {
            name: 'maxDeep',
            field: 'maxDeep',
            label: '최대굴착깊이(m)',
            align: 'center',
            type: 'text',
            style: 'width:16%',
            sortable: true,
          },
          {
            name: 'maxHeight',
            field: 'maxHeight',
            label: '최고높이(m)',
            align: 'center',
            type: 'text',
            style: 'width:16%',
            sortable: true,
          },
          {
            name: 'areaWidth',
            field: 'areaWidth',
            label: '연면적/길이',
            align: 'center',
            type: 'text',
            style: 'width:16%',
            sortable: true,
          },
        ],
        data: [],
      },
      grid2: { // 설계안전보건대장의 안전보건조치 이행계획
        columns: [
          {
            name: 'planNo',
            field: 'planNo',
            type: 'text',
            label: '번호',
            align: 'center',
            sortable: true,
          },
          {
            name: 'adverseRiskFactor',
            field: 'adverseRiskFactor',
            label: '유해위험요인',
            align: 'center',
            type: 'text',
            style: 'width:30%',
            sortable: true,
          },
          {
            name: 'diminishMeasure',
            field: 'diminishMeasure',
            label: '위험성 감소대책',
            align: 'center',
            type: 'text',
            style: 'width:30%',
            sortable: true,
          },
          {
            name: 'executePlan',
            field: 'executePlan',
            label: '시공자 이행계획',
            align: 'center',
            type: 'text',
            style: 'width:30%',
            sortable: true,
          },
        ],
        data: [],
      },
      grid3: { // 유해위험방지계획서 확인결과
        columns: [
          {
            name: 'inspectionName',
            field: 'inspectionName',
            label: '점검자',
            align: 'center',
            style: 'width:15%',
            type: 'text',
            sortable: true,
          },
          {
            name: 'inspectionDt',
            field: 'inspectionDt',
            label: '점검일',
            align: 'center',
            style: 'width:15%',
            type: 'date',
            sortable: true,
          },
          {
            name: 'commentRemark',
            field: 'commentRemark',
            label: '지적사항',
            align: 'center',
            type: 'text',
            style: 'width:35%',
            sortable: true,
          },
          {
            name: 'measureRemark',
            field: 'measureRemark',
            label: '조치사항',
            align: 'center',
            type: 'text',
            style: 'width:35%',
            sortable: true,
          },
        ],
        data: [],
      },
      grid4: { // 산업안전보건관리비 변경내역
        columns: [
          {
            name: 'costDt',
            field: 'costDt',
            label: '일자',
            align: 'center',
            style: 'width:120px',
            type: 'date',
            sortable: true,
          },
          {
            name: 'beginCost',
            field: 'beginCost',
            label: '당초 계상금액',
            align: 'center',
            style: 'width:120px',
            type: 'number',
            sortable: true,
          },
          {
            name: 'changeCost',
            field: 'changeCost',
            label: '변경 계상금액',
            align: 'center',
            style: 'width:120px',
            type: 'number',
            sortable: true,
          },
          {
            name: 'executeCost',
            field: 'executeCost',
            label: '실행금액',
            align: 'center',
            style: 'width:120px',
            type: 'number',
            sortable: true,
          },
          {
            name: 'changeReason',
            field: 'changeReason',
            label: '변경사유',
            align: 'center',
            type: 'text',
            sortable: true,
          },
        ],
        data: [],
      },
      grid5: { // 산업재해예방 지도결과 및 조치내용
        columns: [
          {
            name: 'instructName',
            field: 'instructName',
            label: '지도자',
            align: 'center',
            type: 'text',
            style: 'width:15%',
            sortable: true,
          },
          {
            name: 'instructDt',
            field: 'instructDt',
            label: '지도일',
            align: 'center',
            style: 'width:15%',
            type: 'date',
            sortable: true,
          },
          {
            name: 'commentRemark',
            field: 'commentRemark',
            label: '지적사항',
            align: 'center',
            type: 'text',
            style: 'width:35%',
            sortable: true,
          },
          {
            name: 'measureRemark',
            field: 'measureRemark',
            label: '조치사항',
            align: 'center',
            type: 'text',
            style: 'width:35%',
            sortable: true,
          },
        ],
        data: [],
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      // role setting
      if(this.$store.getters.user.innerFlag === 'N') {
        this.editable = false;
      } else {
        this.editable = this.$route.meta.editable;
      }
      this.infoUrl = selectConfig.pjm.book.get.url,
      this.deleteUrl1 = transactionConfig.pjm.book.delete.summary; // 공사개요
      this.deleteUrl2 = transactionConfig.pjm.book.delete.plan; // 설계안전보건대장의 안전보건조치 이행계획
      this.deleteUrl3 = transactionConfig.pjm.book.delete.measure; // 유해위험방지계획서 확인결과
      this.deleteUrl4 = transactionConfig.pjm.book.delete.safetycost; // 산업안전보건관리비 변경내역
      this.deleteUrl5 = transactionConfig.pjm.book.delete.instruct; // 산업재해예방 지도결과 및 조치내용
      this.printUrl = selectConfig.pjm.book.print.url; // 산업재해예방 지도결과 및 조치내용

      this.getInfo();
    },
    getInfo() {
      if (this.param.plantCd) {
        this.$http.url = this.infoUrl;
        this.$http.type = 'GET';
        this.$http.param = {plantCd: this.param.plantCd}
        this.$http.request((_result) => {
          this.data.info = _result.data.info;
          this.grid1.data = _result.data.summaryList;
          this.grid2.data = _result.data.planList;
          this.grid3.data = _result.data.measureList;
          this.grid4.data = _result.data.safetycodeList;
          this.grid5.data = _result.data.instructList;

          if (_result.data.info.editFlag == 'U') {
            this.nodataFlag = false;
          } else {
            this.data.info.writeDt = this.$comm.getToday();
            this.data.info.writeUserName = this.$store.getters.user.userName;
            this.nodataFlag = true;
          }
        },);
      }
    },
    saveInfo() {
      if (this.data.info.editFlag == 'U') {
        this.saveUrl = transactionConfig.pjm.book.update.url;
        this.mappingType = 'PUT';
      } else if (this.data.info.editFlag == 'C') {
        this.saveUrl = transactionConfig.pjm.book.insert.url;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then((_result) => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.info.regUserId = this.$store.getters.user.userId;
              this.data.info.chgUserId = this.$store.getters.user.userId;
              this.data.info.projectStartDt = this.data.info.projectTerm[0];
              this.data.info.projectEndDt = this.data.info.projectTerm[1];

              let saveData1 = this.grid1.data.filter( x => {
                return x.editFlag == 'C' || x.editFlag == 'U'
              });
              this.$_.forEach(saveData1, item => {
                item.regUserId = this.$store.getters.user.userId;
                item.chgUserId = this.$store.getters.user.userId;
              });
              this.data.summaryList = saveData1;
              
              let saveData2 = this.grid2.data.filter( x => {
                return x.editFlag == 'C' || x.editFlag == 'U'
              });
              this.$_.forEach(saveData2, item => {
                item.regUserId = this.$store.getters.user.userId;
                item.chgUserId = this.$store.getters.user.userId;
              });
              this.data.planList = saveData2;
              
              let saveData3 = this.grid3.data.filter( x => {
                return x.editFlag == 'C' || x.editFlag == 'U'
              });
              this.$_.forEach(saveData3, item => {
                item.regUserId = this.$store.getters.user.userId;
                item.chgUserId = this.$store.getters.user.userId;
              });
              this.data.measureList = saveData3;
              
              let saveData4 = this.grid4.data.filter( x => {
                return x.editFlag == 'C' || x.editFlag == 'U'
              });
              this.$_.forEach(saveData4, item => {
                item.regUserId = this.$store.getters.user.userId;
                item.chgUserId = this.$store.getters.user.userId;
              });
              this.data.safetycodeList = saveData4;
              
              let saveData5 = this.grid5.data.filter( x => {
                return x.editFlag == 'C' || x.editFlag == 'U'
              });
              this.$_.forEach(saveData5, item => {
                item.regUserId = this.$store.getters.user.userId;
                item.chgUserId = this.$store.getters.user.userId;
              });
              this.data.instructList = saveData5;

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {},
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getInfo();
    },
    addrow1() { // 공사개요
      this.grid1.data.push({
        editFlag: 'C',
        plantCd: this.param.plantCd,  // 공사현장코드
        projectBookSummaryCd: uid(),  // 공사개요 일련번호
        mainStructure: '',  // 주요구조물
        structure: '',  // 구조
        gaeso: '',  // 개소
        maxDeep: '',  // 최대굴착깊이(m)
        maxHeight: '',  // 최고높이(m)
        areaWidth: '',  // 연면적/길이
      })
    },
    addrow2() { // 설계안전보건대장의 안전보건조치 이행계획
      this.grid2.data.push({
        editFlag: 'C',
        plantCd: this.param.plantCd,  // 공사현장코드
        projectBookPlanCd: uid(),  // 안전보건조치이행계획 일련번호
        planNo: '',  // 번호
        adverseRiskFactor: '',  // 유해위험요인
        diminishMeasure: '',  // 위험성 감소대책
        executePlan: '',  // 시공자 이행계획
      })
    },
    addrow3() { // 유해위험방지계획서 확인결과
      this.grid3.data.push({
        editFlag: 'C',
        plantCd: this.param.plantCd,  // 공사현장코드
        projectBookMeasureCd: uid(),  // 유해위험방지 조치확인결과 일련번호
        inspectionName: '',  // 점검자
        inspectionDt: '',  // 점검일
        commentRemark: '',  // 지적사항
        measureRemark: '',  // 조치사항
      })
    },
    addrow4() { // 산업안전보건관리비 변경내역
      this.grid4.data.push({
        editFlag: 'C',
        plantCd: this.param.plantCd,  // 공사현장코드
        projectBookSafetyCostCd: uid(),  // 안전관리비변경내역 일련번호
        costDt: '',  // 일자
        beginCost: '',  // 당초 계상금액
        changeCost: '',  // 변경 계상금액
        executeCost: '',  // 실행금액
        changeReason: '',  // 변경사유
      })
    },
    addrow5() { // 산업재해예방 지도결과 및 조치내용
      this.grid5.data.push({
        editFlag: 'C',
        plantCd: this.param.plantCd,  // 공사현장코드
        projectBookInstructCd: uid(),  // 공사안전보건대장 지도결과 일련번호
        instructName: '',  // 지도자
        instructDt: '',  // 지도일
        commentRemark: '',  // 지적사항
        measureRemark: '',  // 조치사항
      })
    },
    deleterow1() { // 공사개요
      let selectData = this.$refs['grid1'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteUrl1);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid1.data = this.$_.reject(this.grid1.data, item);
              })
              this.$refs['grid1'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    deleterow2() { // 설계안전보건대장의 안전보건조치 이행계획
      let selectData = this.$refs['grid2'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteUrl2);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid2.data = this.$_.reject(this.grid2.data, item);
              })
              this.$refs['grid2'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    deleterow3() { // 유해위험방지계획서 확인결과
      let selectData = this.$refs['grid3'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteUrl3);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid3.data = this.$_.reject(this.grid3.data, item);
              })
              this.$refs['grid3'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    deleterow4() { // 산업안전보건관리비 변경내역
      let selectData = this.$refs['grid4'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteUrl4);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid4.data = this.$_.reject(this.grid4.data, item);
              })
              this.$refs['grid4'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    deleterow5() { // 산업재해예방 지도결과 및 조치내용
      let selectData = this.$refs['grid5'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteUrl5);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid5.data = this.$_.reject(this.grid5.data, item);
              })
              this.$refs['grid5'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    printProjectBook() {
      let thisVue = this;
      this.$http.url = this.$format(this.printUrl, this.param.plantCd);
      this.$http.type = "GET";
      this.$http.request(
        (_result) => {
          let fileOrgNm = '공사안전보건대장(' + this.data.info.plantName + ").docx";
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        },
        () => {}
      );
    },
  }
};
</script>
<style lang="sass">
.margin-top-book
  margin-top: -15px
.margin-top-book2
  margin-top: -10px
</style>